(function ($) {
	'use strict';
	$(document).ready(function () {
		$('.wp-block-pixelbound-accordion')
			.find('.wp-block-pixelbound-accordion-panel:first-of-type')
			.find('.wp-block-pixelbound-accordion__panel-content-wrapper')
			.slideDown();

		$('.wp-block-pixelbound-accordion__label').click(function () {
			$(this)
				.next('.wp-block-pixelbound-accordion__panel-content-wrapper')
				.slideToggle();
		});
	});
})(jQuery);
